import React, {useRef, useState, useLayoutEffect} from 'react';
import Icon from 'MAINAPPJS/components/icons/icon/icon';
import {BORDER_TYPE_CLASS_NAMES, FIELD_LABEL_POSITIONS, LABEL_POSITION_CLASS_NAMES} from 'WEBFORMS/config';

const MIN_HEIGHT = 80;
const MAX_HEIGHT = 140;

/**
 * @param {object} props - The properties passed to the component.
 * @param {string} props.name - The name of the input field.
 * @param {string} props.id - The id of the input field.
 * @param {string} props.label - The label for the input field.
 * @param {boolean} props.isRequired - Indicates if the field is required.
 * @param {string} [props.icon] - The icon for the input field.
 * @param {boolean} [props.instantlyShowErrorOnInvalidation] - Show error instantly on invalidation.
 * @param {string} [props.predefinedValue] - The predefined value for the input field.
 * @param {string} props.labelPos - The position of the label.
 * @param {string} [props.helpText] - The help text for the field.
 * @param {string} props.borderType - The type of the border for the input field.
 * @param {string} [props.placeholder] - The placeholder text for the input field.
 * @param {boolean} [props.disabled] - Indicates if the field is disabled.
 * @param {React.RefCallback<HTMLLabelElement>} [props.setLabelRef] - Function to set reference for the label element.
 * @returns {React.ReactElement} The rendered multi line input field.
 */
const MultiLineField = ({
    name,
    id,
    label,
    isRequired,
    icon,
    instantlyShowErrorOnInvalidation = false,
    predefinedValue,
    labelPos,
    helpText,
    borderType,
    placeholder,
    disabled = false,
    setLabelRef,
}) => {
    const [value, setValue] = useState(predefinedValue || '');
    const [isFocused, setIsFocused] = useState(false);
    const textareaRef = /** @type {React.RefObject<HTMLTextAreaElement | null>} */ (useRef(null));

    useLayoutEffect(() => {
        setValue(predefinedValue || '');
        if (predefinedValue && textareaRef.current) {
            textareaRef.current.style.height = getHeight(textareaRef.current.scrollHeight);
        }
    }, [predefinedValue]);

    const onFocus = () => setIsFocused(true);

    const onBlur = () => setIsFocused(false);

    const getHeight = (scrollHeight) => {
        if (scrollHeight <= MIN_HEIGHT) {
            return `${MIN_HEIGHT}px`;
        }

        if (scrollHeight >= MAX_HEIGHT) {
            return `${MAX_HEIGHT}px`;
        }

        return `${scrollHeight}px`;
    };

    const onInput = (e) => {
        setValue(e.target.value);

        if (textareaRef.current) {
            textareaRef.current.style.height = '0';
            const scrollHeight = e.target.scrollHeight;
            textareaRef.current.style.height = getHeight(scrollHeight);
        }
    };

    const classNames = ['multiline-field', 'field-box', LABEL_POSITION_CLASS_NAMES[labelPos]];
    isFocused && classNames.push('__focused');
    !!value.trim() && classNames.push('__filled');
    !!icon && classNames.push('__with-icon');
    instantlyShowErrorOnInvalidation && classNames.push('was-validated');
    placeholder && classNames.push('__with-placeholder');
    disabled && classNames.push('__disabled');

    const labelClassNames = ['multiline-field-label'];
    if (placeholder && labelPos === FIELD_LABEL_POSITIONS.inside) {
        labelClassNames.push('__active');
    }

    const errorMsg = 'This field is required';

    const iconJSX = !!icon && <Icon iconValue={icon} className="input-icon" />;

    const isEmpty = !value.trim().length;
    const isInvalid = isRequired && isEmpty;
    const tabIndex = disabled ? -1 : 1;

    return (
        <div className={classNames.join(' ')}>
            <label
                htmlFor={id}
                className={labelClassNames.join(' ')}
                ref={setLabelRef}
                data-required={isRequired}
                title={label}>
                {labelPos === 'left' && iconJSX}
                <span>{label}</span>
            </label>
            <div className="multiline-field-input-wrapper">
                {labelPos !== 'left' && iconJSX}
                <textarea
                    name={name}
                    id={id}
                    ref={textareaRef}
                    value={value}
                    className={`multiline-field-input ${BORDER_TYPE_CLASS_NAMES[borderType]}`}
                    aria-invalid={isInvalid}
                    data-empty={isEmpty}
                    data-invalid={isInvalid}
                    data-required={isRequired}
                    placeholder={placeholder}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onInput={onInput}
                    tabIndex={tabIndex}
                    aria-errormessage={`error-msg-${id}`}
                    aria-describedby={helpText ? `help-text-${id}` : ''}
                />
                <span className="required-label-placeholder" />
                <div className="messages">
                    <p className="error-msg" id={`error-msg-${id}`} title={errorMsg}>
                        {errorMsg}
                    </p>
                    {!!helpText && (
                        <p className="help-text" id={`help-text-${id}`} title={helpText}>
                            {helpText}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MultiLineField;

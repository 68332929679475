import React from 'react';
import DOMPurify from 'dompurify';
import {BUTTON_APPEARANCES, BUTTON_FORMS} from 'WEBFORMS/config';

/**
 * @param {object} props - The props.
 * @param {string} [props.label] - The button label.
 * @param {string} [props.type] - The button type.
 * @param {boolean} [props.disabled] - Whether the button is disabled.
 * @param {string} [props.form] - The button alignment.
 * @param {boolean} [props.isLoading] - Whether the button is loading.
 * @param {string} [props.textUnderSubmitButton] - The html string of the text under the submit button.
 * @returns {React.ReactElement} - The submit button.
 */
const SubmitButton = ({
    label = '',
    type = BUTTON_APPEARANCES.primary,
    disabled = false,
    form = BUTTON_FORMS.stretch,
    isLoading = false,
    textUnderSubmitButton = '',
}) => {
    const typeClassName = `__${type}`;
    const loadingClassName = isLoading ? '__loading' : '';
    const tabIndex = disabled ? -1 : 1;

    const positionClassName = {
        [BUTTON_FORMS.left]: '__left',
        [BUTTON_FORMS.center]: '__center',
        [BUTTON_FORMS.right]: '__right',
        [BUTTON_FORMS.stretch]: '__stretch',
    }[form];

    /**
     * @returns {string} - Sanitized text under submit button.
     */
    const getTextUnderSubmitButtonJSX = () => {
        return textUnderSubmitButton ? DOMPurify.sanitize(textUnderSubmitButton, {ADD_ATTR: ['target']}) : '';
    };

    /**
     * @param {string} text - The text to check.
     * @returns {boolean} - Whether the text is empty.
     */
    const isTextUnderSubmitButtonNotEmpty = (text) => {
        if (!text) {
            return false;
        }

        const div = document.createElement('div');
        // eslint-disable-next-line
        div.innerHTML = text;
        return !!div.textContent?.trim();
    };

    const textUnderSubmitButtonJSX = getTextUnderSubmitButtonJSX();

    return (
        <div className={`submit-button-wrapper ${positionClassName}`}>
            <button
                type="submit"
                className={`submit-button ${typeClassName} ${loadingClassName}`}
                aria-disabled={disabled}
                data-disabled={disabled}
                tabIndex={tabIndex}>
                <span className="submit-button__label">{label}</span>
            </button>
            {isTextUnderSubmitButtonNotEmpty(textUnderSubmitButtonJSX) && (
                <div
                    className="text-under-submit-button"
                    dangerouslySetInnerHTML={{
                        __html: textUnderSubmitButtonJSX,
                    }}
                />
            )}
        </div>
    );
};

export default SubmitButton;

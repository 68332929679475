import React, {useEffect, useState} from 'react';
import z from 'zod';
import Icon from 'MAINAPPJS/components/icons/icon/icon';
import {BORDER_TYPE_CLASS_NAMES, FIELD_LABEL_POSITIONS, LABEL_POSITION_CLASS_NAMES} from 'WEBFORMS/config';

/**
 * EmailField component for rendering an email input field with validation.
 * @param {object} props - The properties passed to the component.
 * @param {string} props.name - The name of the input field.
 * @param {string} props.id - The id of the input field.
 * @param {string} props.label - The label for the input field.
 * @param {boolean} props.isRequired - Indicates if the field is required.
 * @param {string} props.icon - An optional icon for the input field.
 * @param {boolean} [props.instantlyShowErrorOnInvalidation] - Show error instantly on invalidation.
 * @param {string} [props.predefinedValue] - The predefined value for the input field.
 * @param {string} props.labelPos - The position of the label.
 * @param {string} props.helpText - An optional help text for the field.
 * @param {string} props.borderType - The type of border for the input field.
 * @param {string} props.placeholder - The placeholder text for the input field.
 * @param {boolean} [props.disabled] - Indicates if the field is disabled.
 * @param {Function} [props.setLabelRef] - Function to set reference for the label element.
 * @returns {React.ReactElement} The rendered email input field.
 */
const EmailField = ({
    name,
    id,
    label,
    isRequired,
    icon,
    instantlyShowErrorOnInvalidation = false,
    predefinedValue,
    labelPos,
    helpText,
    borderType,
    placeholder,
    disabled = false,
    setLabelRef,
}) => {
    const [value, setValue] = useState(predefinedValue || '');
    const [isFocused, setIsFocused] = useState(false);

    const isEmpty = !value.trim().length;
    const errorMsg = isEmpty ? 'This field is required' : 'Enter valid email';
    const tabIndex = disabled ? -1 : 1;

    const [isInvalid, setIsInvalid] = useState(isRequired && isEmpty);

    useEffect(() => {
        setValue(predefinedValue || '');
    }, [predefinedValue]);

    /**
     * Validates an email address based on specified criteria.
     * @param {string} email - The email address to validate.
     * @returns {boolean} True if the email address is valid, otherwise false.
     */
    const isValidEmail = (email) => {
        const emailSchema = z.string().email();
        try {
            emailSchema.parse(email);
            return true;
        } catch {
            return false;
        }
    };

    const onFocus = () => {
        setIsFocused(true);
    };

    const onBlur = () => {
        setIsFocused(false);
    };

    const onInput = (e) => {
        const newValue = e.target.value;
        setIsInvalid(!isValidEmail(newValue));
        setValue(newValue);
    };

    const classNames = ['singleline-field', 'field-box', LABEL_POSITION_CLASS_NAMES[labelPos]];
    isFocused && classNames.push('__focused');
    !!value.trim() && classNames.push('__filled');
    !!icon && classNames.push('__with-icon');
    instantlyShowErrorOnInvalidation && classNames.push('was-validated');
    placeholder && classNames.push('__with-placeholder');
    disabled && classNames.push('__disabled');

    const labelClassNames = ['singleline-field-label'];
    if (placeholder && labelPos === FIELD_LABEL_POSITIONS.inside) {
        labelClassNames.push('__active');
    }

    const iconJSX = !!icon && <Icon iconValue={icon} className="input-icon" />;

    return (
        <div className={classNames.join(' ')}>
            <label
                htmlFor={id}
                className={labelClassNames.join(' ')}
                ref={setLabelRef}
                data-required={isRequired}
                title={label}>
                {labelPos === FIELD_LABEL_POSITIONS.left && iconJSX}
                <span>{label}</span>
            </label>
            <div className="singleline-field-input-wrapper">
                {labelPos !== FIELD_LABEL_POSITIONS.left && iconJSX}
                <input
                    type="email"
                    name={name}
                    id={id}
                    className={`singleline-field-input ${BORDER_TYPE_CLASS_NAMES[borderType]}`}
                    value={value}
                    aria-invalid={isInvalid}
                    data-empty={isEmpty}
                    data-invalid={isInvalid}
                    data-required={isRequired}
                    placeholder={placeholder}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onInput={onInput}
                    tabIndex={tabIndex}
                    aria-errormessage={`error-msg-${id}`}
                    aria-describedby={helpText ? `help-text-${id}` : ''}
                />
                <span className="required-label-placeholder" />
                <div className="messages">
                    {(isRequired || !isValidEmail(value)) && (
                        <p className="error-msg" id={`error-msg-${id}`} title={errorMsg}>
                            {errorMsg}
                        </p>
                    )}
                    {!!helpText && (
                        <p className="help-text" id={`help-text-${id}`} title={helpText}>
                            {helpText}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default EmailField;

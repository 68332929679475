import React, {useEffect, useState} from 'react';
import Icon from 'MAINAPPJS/components/icons/icon/icon';
import {BORDER_TYPE_CLASS_NAMES, FIELD_LABEL_POSITIONS, LABEL_POSITION_CLASS_NAMES} from 'WEBFORMS/config';

/**
 * NumberField component for rendering an number field.
 * @param {object} props - The properties passed to the component.
 * @param {string} props.name - The name of the input field.
 * @param {string} props.id - The id of the input field.
 * @param {string} props.label - The label for the input field.
 * @param {boolean} props.isRequired - Indicates if the field is required.
 * @param {string} props.icon - An optional icon for the input field.
 * @param {boolean} [props.instantlyShowErrorOnInvalidation] - Show error instantly on invalidation.
 * @param {string} [props.predefinedValue] - The predefined value for the input field.
 * @param {string} props.labelPos - The position of the label.
 * @param {string} props.helpText - An optional help text for the field.
 * @param {string} props.borderType - The type of border for the input field.
 * @param {string} props.placeholder - The placeholder text for the input field.
 * @param {boolean} [props.disabled] - Indicates if the field is disabled.
 * @param {Function} [props.setLabelRef] - Function to set reference for the label element.
 * @returns {React.ReactElement} The rendered email input field.
 */
const NumberField = ({
    name,
    id,
    label,
    isRequired,
    icon,
    instantlyShowErrorOnInvalidation = false,
    predefinedValue,
    labelPos,
    helpText,
    borderType,
    placeholder,
    disabled = false,
    setLabelRef,
}) => {
    const initialValue = predefinedValue ? Number(predefinedValue) : undefined;

    const [value, setValue] = useState(initialValue);
    const [isFocused, setIsFocused] = useState(false);

    const errorMsg = 'This field is required';
    const isEmpty = value === undefined || value === null || isNaN(value);
    const tabIndex = disabled ? -1 : 1;

    const [isInvalid, setIsInvalid] = useState(isRequired && isEmpty);

    /**
     * Validates a number input value, ensuring it is required and not empty.
     * @param {number | undefined} value - The input value as a number.
     * @param {boolean} isRequired - Indicates if the field is required.
     * @returns {boolean} True if the value is valid (not empty when required), otherwise false.
     */
    const isValidNumber = (value, isRequired) => {
        if (isRequired) {
            return value !== undefined && value !== null && !isNaN(value);
        }
        return true;
    };

    useEffect(() => {
        setValue(initialValue);
        setIsInvalid(!isValidNumber(initialValue, isRequired));
    }, [initialValue, isRequired]);

    const onFocus = () => {
        setIsFocused(true);
    };

    const onBlur = () => {
        setIsFocused(true);
        setIsInvalid(!isValidNumber(value, isRequired));
    };

    const onInput = (e) => {
        isInvalid && setIsInvalid(false);
        setValue(e.target.valueAsNumber);
    };

    const classNames = ['singleline-field', 'field-box', LABEL_POSITION_CLASS_NAMES[labelPos]];
    isFocused && classNames.push('__focused');
    !!String(value).trim() && classNames.push('__filled');
    !!icon && classNames.push('__with-icon');
    instantlyShowErrorOnInvalidation && classNames.push('was-validated');
    placeholder && classNames.push('__with-placeholder');
    disabled && classNames.push('__disabled');

    const labelClassNames = ['singleline-field-label'];
    if (placeholder && labelPos === FIELD_LABEL_POSITIONS.inside) {
        labelClassNames.push('__active');
    }

    const iconJSX = !!icon && <Icon iconValue={icon} className="input-icon" />;

    return (
        <div className={classNames.join(' ')}>
            <label
                htmlFor={id}
                className={labelClassNames.join(' ')}
                ref={setLabelRef}
                data-required={isRequired}
                title={label}>
                {labelPos === FIELD_LABEL_POSITIONS.left && iconJSX}
                <span>{label}</span>
            </label>
            <div className="singleline-field-input-wrapper">
                {labelPos !== FIELD_LABEL_POSITIONS.left && iconJSX}
                <input
                    type="number"
                    name={name}
                    id={id}
                    className={`singleline-field-input ${BORDER_TYPE_CLASS_NAMES[borderType]}`}
                    value={value}
                    aria-invalid={isInvalid}
                    data-empty={isEmpty}
                    data-invalid={isInvalid}
                    data-required={isRequired}
                    placeholder={placeholder}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onInput={onInput}
                    tabIndex={tabIndex}
                    aria-errormessage={`error-msg-${id}`}
                    aria-describedby={helpText ? `help-text-${id}` : ''}
                />
                <span className="required-label-placeholder" />
                <div className="messages">
                    {isRequired && (
                        <p className="error-msg" id={`error-msg-${id}`} title={errorMsg}>
                            {errorMsg}
                        </p>
                    )}
                    {!!helpText && (
                        <p className="help-text" id={`help-text-${id}`} title={helpText}>
                            {helpText}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default NumberField;
